import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  inject,
  Input,
  output,
  QueryList,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { collapsibleGroupAnimation } from '@design/navigation/collapsible-group/collapsible-group.animation';
import { SubnavItemComponent } from '@design/navigation/subnav-item/subnav-item.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@UntilDestroy()
@Component({
  selector: 'cc-collapsible-group',
  standalone: true,
  templateUrl: './collapsible-group.component.html',
  styleUrls: ['./collapsible-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapsibleGroupAnimation],
  imports: [CommonModule, ButtonComponent, ObserversModule, TooltipDirective, TranslateModule],
})
export class CollapsibleGroupComponent {
  @Input()
  title: string = '';

  @Input()
  style: 'default' | 'team-workspace' = 'default';

  @Input()
  expanded: boolean = true;

  childSelected = output<void>();

  @ContentChildren(SubnavItemComponent)
  children: QueryList<SubnavItemComponent>;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly cdr = inject(ChangeDetectorRef);

  expand(): void {
    this.expanded = true;
    this.cdr.detectChanges();
    this.childSelected.emit();
  }
}
