@switch (style) {
  @case ('default') {
    <div
      [attr.aria-expanded]="expanded"
      [class.collapsible-group--collapsed]="!expanded"
      class="collapsible-group"
    >
      <div class="collapsible-group__header">
        <div class="collapsible-group__header-title">{{ title }}</div>
        <div class="collapsible-group__header-actions">
          <app-button
            (click)="expanded = !expanded"
            [ccTooltip]="expanded ? ('common.buttons.collapse' | translate) : ('common.buttons.expand' | translate)"
            [size]="ButtonSize.Small"
            [type]="ButtonType.Ghost"
            class="collapsible-group__header-actions-toggle"
          >
            <i
              class="icon-right-small-fill collapsible-group__header-actions-toggle-icon"
              slot="ghost-icon"
            ></i>
          </app-button>
          <ng-container [ngTemplateOutlet]="actions"></ng-container>
        </div>
      </div>
      <div
        [@collapsibleGroupAnimation]="expanded"
        [class.collapsible-group__content--collapsed]="!expanded"
        class="collapsible-group__content"
      >
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    </div>
  }
  @case ('team-workspace') {
    <div
      [attr.aria-expanded]="expanded"
      [class.team-workspace-collapsible-group--collapsed]="!expanded"
      class="team-workspace-collapsible-group"
    >
      <div
        (click)="expanded = !expanded"
        class="team-workspace-collapsible-group__header"
      >
        <div class="team-workspace-collapsible-group__header-title">{{ title }}</div>
        <div class="team-workspace-collapsible-group__header-toggle">
          <i
            class="icon-right-small-fill team-workspace-collapsible-group__header-toggle-icon"
            slot="ghost-icon"
          ></i>
        </div>
        <div class="team-workspace-collapsible-group__header-actions">
          <ng-container [ngTemplateOutlet]="actions"></ng-container>
        </div>
      </div>
      <div
        [@collapsibleGroupAnimation]="expanded"
        [class.team-workspace-collapsible-group__content--collapsed]="!expanded"
        class="team-workspace-collapsible-group__content"
      >
        <div class="team-workspace-collapsible-group__content-top-divider"></div>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
        <div class="team-workspace-collapsible-group__content-bottom-divider"></div>
      </div>
    </div>
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #actions>
  <ng-content select="[slot='collapsible-group-action']"></ng-content>
</ng-template>
